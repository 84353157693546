
const Browse = () => {
    return (
      <div>
        Hello Browse
      </div>
    );
  }
  
  export default Browse;
  